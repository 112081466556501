import React from "react";
import { useQuery, gql } from "@apollo/client";
import { UserCountry, User } from "@rp-p1-core/cms-schema";
import { createUserIsAuthorisedToView } from "./userIsAuthorisedToView";

export interface UserData extends User {
    countries?: UserCountry[];
    selectedCountryId: number;
    setSelectedCountryId: (countryId: number) => void;
}

export interface AuthenticationContextValue {
    userData: UserData;
    canUserView: (roles: number[]) => boolean;
    isAuthenticating: boolean;
    isNotAuthorised: boolean;
}

interface GetCurrentUserQuery {
    currentUser: User;
}

const GET_CURRENT_USER_QUERY = gql`
    query GetCurrentUser {
        currentUser: getCurrentUser {
            id
            roleId
            email
            preferredLanguageId
            preferredLanguage
            countries {
                id
                name
            }
        }
    }
`;

export const AuthenticationContext = React.createContext<
    AuthenticationContextValue | undefined
>(undefined);

const isBrowser = typeof window !== "undefined";

const getSelectedId = () => {
    if (isBrowser) {
        const id = localStorage.getItem("countryId");
        if (!id) {
            return 0;
        }

        return parseInt(id);
    }

    return 0;
};

const AuthenticationProvider: React.FC = (props) => {
    const [countryId, setCountryId] = React.useState(() => getSelectedId());

    const setSelectedCountryId = (id: number) => {
        setCountryId(id);
        // set the countryId to local storage so that it can be reused on hard reloads
        if (isBrowser) {
            localStorage.setItem("countryId", id.toString());
        }
    };

    const { data, loading, error } = useQuery<GetCurrentUserQuery>(
        GET_CURRENT_USER_QUERY,
        {
            onCompleted: (data) => {
                // if the user only has access to one country this should always be set as the country Id
                if (data?.currentUser?.countries?.length === 1) {
                    setSelectedCountryId(data.currentUser.countries[0].id);
                }
            }
        }
    );

    // creates the user data to pass down through the context provider
    const value = React.useMemo(() => {
        const userData = {
            ...data?.currentUser,
            selectedCountryId: countryId,
            setSelectedCountryId
        };

        const isNotAuthorised =
            typeof error !== "undefined" || !data?.currentUser;

        const authData = {
            userData,
            canUserView: createUserIsAuthorisedToView(userData?.roleId!),
            isAuthenticating: loading,
            isNotAuthorised
        };

        return authData;
    }, [data, loading, error, countryId]);

    return <AuthenticationContext.Provider value={value} {...props} />;
};

const useAuthentication = () => {
    const context = React.useContext(AuthenticationContext);

    if (!context) {
        throw new Error(
            "useAuthentication must be used within an AuthenticationProvider"
        );
    }

    return context;
};

export { AuthenticationProvider, useAuthentication };
