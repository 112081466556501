import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
    gql,
    ServerError
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch";

import { Podcasts } from "@rp-p1-core/cms-schema";
import isLocalhost from "../utilities/isLocalHost";
import { ErrorInfo } from "react";

import { getFullName } from "@rp-p1-core/isomorphic-utils";

export const typeDefs = gql`
    extend type Query {
        countryId: Int
    }
`;

const error = onError(({ graphQLErrors, networkError, response }) => {
    try {
        if (!isLocalhost() && networkError) {
            // 401 error is emitted from load balancer. See cms-api.tf "cms_api_https_listener_unauth"
            if ((networkError as ServerError).statusCode.toString() === "401") {
                window.location.replace(
                    `${window.location.origin}/oauth2/signin/`
                );
                return;
            }
        }
    } catch (error) {
        console.warn(JSON.stringify(error));
    }

    if (graphQLErrors) {
        graphQLErrors.map((error) => {
            const { message, extensions } = error;

            console.error(
                `%c[GQL Error]\n %cMessage: ${message}\n %cCode: ${extensions?.code}\n %cOriginal Error: ${extensions?.originalError?.message}`,
                "color:pink",
                "color:orange",
                "color:yellow",
                "color:orange"
            );
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

// add selected country to middleware
const countryMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            "country-id": localStorage.getItem("countryId")
        }
    }));

    return forward(operation);
});

const uri =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3000/graphql"
        : "/graphql";

const httpLink = createHttpLink({
    uri,
    credentials: "same-origin",
    fetch
});

export const client = new ApolloClient({
    link: ApolloLink.from([error, countryMiddleware, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            StationType: {
                fields: {
                    streams: {
                        // as streams are managed by the custom update functions
                        // in their mutation hooks, this type policy only needs
                        // to return the latest data. reference:
                        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
                        merge: false
                    }
                }
            },

            UserType: {
                fields: {
                    fullName(existing = null, { readField }) {
                        if (existing) {
                            return existing;
                        }

                        const firstName = readField<string>({
                            fieldName: "firstName"
                        });
                        const lastName = readField<string>({
                            fieldName: "lastName"
                        });
                        const username = readField<string>({
                            fieldName: "username"
                        });

                        return getFullName({ firstName, lastName, username });
                    }
                }
            },

            Query: {
                // This keyFields array helps apollo understand where to search
                // for ids to normalise & merge objects in the cache
                keyFields: ["results", ["id"]],

                fields: {
                    listPodcasts: {
                        read(existing: Podcasts) {
                            // A read function should always return undefined if existing is
                            // undefined. Returning undefined signals that the field is
                            // missing from the cache, which instructs Apollo Client to
                            // fetch its value from your GraphQL server.
                            return existing;
                        },
                        keyArgs: [],
                        merge: (
                            existing: Podcasts,
                            incoming: Podcasts,
                            { args }
                        ) => {
                            // if there is exisiting data and no offset passed in:
                            // - it is a refresh after an edit
                            // - or when a filter is set, the offset is reset
                            //   preventing conflicting existing & incoming
                            //   results being merged together
                            if (
                                existing?.results?.length &&
                                !args?.page?.offset
                            ) {
                                return incoming;
                            }
                            // Slicing is necessary because the existing data is
                            // immutable, and frozen in development.
                            const exisitingPodcasts = existing?.results
                                ? existing?.results?.slice(0)
                                : [];

                            const incomingPodcasts = incoming?.results || [];

                            const newResults = [
                                ...exisitingPodcasts,
                                ...incomingPodcasts
                            ];

                            const result = {
                                results: newResults,
                                totalCount: incoming?.totalCount
                            };

                            return result;
                        }
                    }
                }
            }
        }
    }),
    connectToDevTools: true,
    typeDefs
});
