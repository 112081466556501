import React from "react";
import { ApolloProvider } from "@apollo/client";
import { AuthenticationProvider } from "@/context/Authentication";
import { client } from "./client";

interface Props {
    element: string;
}

export const wrapRootElement: React.FC<Props> = ({ element }) => (
    <ApolloProvider client={client}>
        <AuthenticationProvider>{element}</AuthenticationProvider>
    </ApolloProvider>
);
