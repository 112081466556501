export const createUserIsAuthorisedToView =
    (roleId: number) => (roles: number[]) => {
        if (!roleId) {
            return false;
        }

        if (roles.includes(roleId)) {
            return true;
        }

        return false;
    };
